var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _vm._m(0),
    _c("div", { staticClass: "block" }, [
      _c("b", [_vm._v("Q1.")]),
      _vm._v(" Tell your gender."),
      _c("br"),
      _c(
        "div",
        { staticClass: "flex" },
        [
          _c(
            "v-radio-group",
            {
              directives: [
                {
                  name: "nano",
                  rawName: "v-nano.required",
                  modifiers: { required: true },
                },
              ],
              attrs: { row: "" },
              model: {
                value: _vm.gender,
                callback: function ($$v) {
                  _vm.gender = $$v
                },
                expression: "gender",
              },
            },
            [
              _c(
                "div",
                { staticClass: "check-item" },
                [_c("v-radio", { attrs: { label: "Male", value: "Male" } })],
                1
              ),
              _c(
                "div",
                { staticClass: "check-item" },
                [
                  _c("v-radio", {
                    attrs: { label: "Female", value: "Female" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "check-item" },
                [_c("v-radio", { attrs: { label: "Other", value: "Other" } })],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "block" }, [
      _c("b", [_vm._v("Q2.")]),
      _vm._v(" Tell your age. (Please input a single-byte number.)"),
      _c("br"),
      _c("input", {
        directives: [
          {
            name: "nano",
            rawName: "v-nano.required",
            modifiers: { required: true },
          },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.agetext,
            expression: "agetext",
          },
        ],
        attrs: { type: "number", min: "0", max: "120" },
        domProps: { value: _vm.agetext },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.agetext = $event.target.value
          },
        },
      }),
    ]),
    _c("div", { staticClass: "next-btn-wrapper" }, [
      _c(
        "button",
        {
          attrs: { disabled: !_vm.canSubmit },
          on: {
            click: function ($event) {
              _vm.canSubmit ? _vm.submit() : false
            },
          },
        },
        [_vm._v("NEXT")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "block" }, [
      _c("b", [_vm._v("Pre-survey.")]),
      _vm._v(" Please answer the following questions first. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }