var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._m(1),
    _c("p", { staticClass: "mb5" }),
    _c("p"),
    _vm._m(2),
    _c("ul", [
      _c("li", [
        _vm._v(
          "You cannot move onto the next question until you listen to both voice A and B at least once for each."
        ),
      ]),
      _c("li", [
        _vm._v("While one voice is playing, the other voice cannot be played."),
      ]),
      _c("li", [
        _vm._v(
          'You cannot choose "which is the better sound quality" while the voice is playing.'
        ),
      ]),
      _c(
        "li",
        [
          _vm._v("In case "),
          _c("font", { attrs: { color: "red" } }, [
            _vm._v("any technical issue"),
          ]),
          _vm._v(" occurs (which prevents you to move forward), "),
          _c("b", [_vm._v("provide details and click the button to skip")]),
          _vm._v("."),
        ],
        1
      ),
    ]),
    _c("p", { staticClass: "mb5" }),
    _c("p"),
    _c("p", [
      _vm._v(
        "If you have questions, contact us at mturk04@pcl.cs.waseda.ac.jp."
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", [_c("b", [_vm._v("Instructions :")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v(
          "Listen to voice A and B, and select the voice that you think has less distortion."
        ),
      ]),
      _c("li", [
        _vm._v(
          'Possible choices are "Definitely A", "Maybe A", "Maybe B", and "Definitely B".'
        ),
      ]),
      _c("li", [
        _vm._v(" The all tasks habe a total of "),
        _c("b", [_vm._v("33 questions")]),
        _vm._v(".(Estimated completion time : 15 [min])"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", [_c("b", [_vm._v("Note :")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }