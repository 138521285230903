var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("br"),
      _c("h3", [
        _vm._v(
          "Compare Voice A and Voice B, and select the more natural voice."
        ),
      ]),
      _c("p", [
        _vm._v(
          "Once you have selected, you will be automatically moved to the next question."
        ),
      ]),
      _c("center", [
        _c("ul", { staticClass: "table" }, [
          _c(
            "li",
            [
              _c("p", [_c("b", [_vm._v("Voice A")])]),
              _c(
                "v-btn",
                {
                  staticClass: "play-btn",
                  attrs: {
                    elevation: "2",
                    disabled: !_vm.audio.A.canPlay || _vm.audio.B.playing,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.togglePlaying("A")
                    },
                  },
                },
                [
                  !_vm.audio.A.canPlay
                    ? _c("v-icon", [_vm._v("mdi-cached")])
                    : !_vm.audio.A.playing
                    ? _c("v-icon", [_vm._v("mdi-play")])
                    : _c("v-icon", [_vm._v("mdi-stop")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("v-icon", { attrs: { "x-large": "" } }, [
                _vm._v("mdi-arrow-left-right-bold"),
              ]),
            ],
            1
          ),
          _c(
            "li",
            [
              _c("p", [_c("b", [_vm._v("Voice B")])]),
              _c(
                "v-btn",
                {
                  staticClass: "play-btn",
                  attrs: {
                    elevation: "2",
                    disabled: !_vm.audio.B.canPlay || _vm.audio.A.playing,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.togglePlaying("B")
                    },
                  },
                },
                [
                  !_vm.audio.B.canPlay
                    ? _c("v-icon", [_vm._v("mdi-cached")])
                    : !_vm.audio.B.playing
                    ? _c("v-icon", [_vm._v("mdi-play")])
                    : _c("v-icon", [_vm._v("mdi-stop")]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("br"),
        _c(
          "div",
          { staticClass: "ansbtn" },
          [
            _c(
              "v-btn",
              {
                attrs: {
                  elevation: "2",
                  height: "50",
                  width: "130",
                  color: "#32cd32",
                  disabled:
                    !_vm.allPlayedOnce ||
                    _vm.audio.A.playing ||
                    _vm.audio.B.playing,
                },
                on: {
                  click: function ($event) {
                    _vm.nano.ans.choice = "A"
                    _vm.nano.ans.submit_time = Math.floor(
                      new Date().getTime() / 1000
                    )
                    _vm.submit()
                  },
                },
              },
              [_vm._v("A")]
            ),
            _c(
              "v-btn",
              {
                attrs: {
                  elevation: "2",
                  height: "50",
                  width: "130",
                  color: "#00bfff",
                  disabled:
                    !_vm.allPlayedOnce ||
                    _vm.audio.A.playing ||
                    _vm.audio.B.playing,
                },
                on: {
                  click: function ($event) {
                    _vm.nano.ans.choice = "B"
                    _vm.nano.ans.submit_time = Math.floor(
                      new Date().getTime() / 1000
                    )
                    _vm.submit()
                  },
                },
              },
              [_vm._v("B")]
            ),
          ],
          1
        ),
      ]),
      _c("p", { staticClass: "mb5" }),
      _c("p"),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("center", [
            _c("p", [_vm._v("Can't play audio or have any problems?")]),
            _c("input", {
              directives: [
                { name: "nano", rawName: "v-nano" },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.problem,
                  expression: "problem",
                },
              ],
              attrs: {
                type: "text",
                placeholder: "Please let us know details...",
              },
              domProps: { value: _vm.problem },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.problem = $event.target.value
                },
              },
            }),
          ]),
          _c("br"),
          _c("div", { staticClass: "skip-btn-wrapper" }, [
            _c(
              "button",
              {
                attrs: { disabled: _vm.problem == null },
                on: {
                  click: function ($event) {
                    return _vm.submit()
                  },
                },
              },
              [_vm._v("CLICK HERE TO SKIP (※ONLY WHEN YOU FACE ANY PROBLEMS)")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }